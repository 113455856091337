<template>
    <b-form ref="addForm" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
    :save-params="{api_key: currentUser.api_key}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Event Title" v-model="model.event_title"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <checkbox-input label="Disabled" v-model="model.disabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddGalleryEvent',
    computed : { ...mapGetters(['currentUser']) },

    data () {
        return {
            addUrl : urls.admin.event.gallery.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Gallery..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
