<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" @failure="formFailure"
            :save-params="{api_key: currentUser.api_key}">

        <div class="col-12">
            <validated-input name="Title" label="Title" v-model="model.title"
                             :rules="{required:true}" :disabled="loading"/>
        </div>
        <div class="col-12">
            <file-input class="c-file-input" label="Thumbnail" v-model="model.thumbnail" :disabled="loading"/>
        </div>
        <div class="col-12">
            <image-info :width="690" :height="500"/>
        </div>
        <div class="col-12">
            <validated-input name="Video Link" label="Video Link" v-model="model.video_link"
                             :rules="{required:true}" :disabled="loading"/>
        </div>
        <div class="col-12">
            <checkbox-input class="col-6" label="Disable" v-model="model.disabled"
                            :rules="{required:false}" :disabled="loading"/>
        </div>
        <div class="col-12 mt-2">
            <btn class="trn" size="sm" :loading="loading" color="primary" text="Save"
                 loading-text="Saving..." :disabled="loading"/>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'AddVideo',
    computed : { ...mapGetters(['currentUser']) },
    data () {
        return {
            addUrl : urls.admin.event.videoGallery.addEdit
        };
    },

    methods : {
        formSuccess () {
            this.$notify('Successfully Added Video..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        },
        formFailure (response) {
            const json = response.data;
            if (json.permission === false) {
                this.$notify('Invalid Authentication..!', 'Permission Denied',
                    {
                        type : 'warning'
                    }
                );
            }
        }
    }
};
</script>

<style scoped>

</style>
