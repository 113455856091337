<template>
    <div class="card bs-4">
        <div class="fl-te-c mb-3">
            <h4>Gallery Images</h4>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </div>
        <simple-table v-if="details && details.length!==0" :data="details" :fields="fields" ref="table">
            <template #image="{rowData}">
                <img :src="rowData.image" width="200" height="200" alt="Avatar"/>
            </template>
            <template #is_thumbnail="{rowData}">
                <p v-if="rowData.is_thumbnail">Yes</p>
                <p v-else>No</p>
            </template>
            <template #disabled="{rowData}">
                <p v-if="rowData.disabled">Yes</p>
                <p v-else>No</p>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </simple-table>
        <p v-else class="text-center p-3">No data available</p>
        <modal title="Add Image" ref="addModal" width="30r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
                    :save-params="{gallery_id, api_key: currentUser.api_key}">
                <div class="row">
                    <div class="col-12">
                        <file-input class="c-file-input" label="Image" v-model="model.image" :disabled="loading"/>
                    </div>
                    <div class="col-12">
                        <image-info :width="360" :height="360"/>
                    </div>
                    <div class="col-6">
                        <checkbox-input label="Is Thumbnail" v-model="model.is_thumbnail" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <checkbox-input label="Disabled" v-model="model.disabled" :disabled="loading"/>
                    </div>
                    <div class="col-12 text-right">
                        <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                             :loading="loading"/>
                    </div>
                </div>
            </b-form>
        </modal>
        <modal title="Edit Image" ref="editModal" width="30r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addUrl" @success="formSuccess1" v-slot="{model, loading}"
                    :save-params="{gallery_id, api_key: currentUser.api_key}" :initial-data="editingItem">
                <div class="row">
                    <div class="col-12">
                        <div class="fl-te-c mb-2">
                            <label>Image</label>
                            <btn v-if="editingItem.image" @click="viewFile(editingItem.image)"
                                 type="button" class="fa fa-eye" size="xs"/>
                        </div>
                        <file-input class="c-file-input" ref="file" @input="clearFile" v-model="model.image"
                                    :disabled="loading">
                        </file-input>
                    </div>
                    <div class="col-12">
                        <image-info :width="360" :height="360"/>
                    </div>
                    <div class="col-6">
                        <checkbox-input label="Is Thumbnail" v-model="model.is_thumbnail" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <checkbox-input label="Disabled" v-model="model.disabled" :disabled="loading"/>
                    </div>
                </div>
                <div class="col-12 text-right">
                    <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                         :loading="loading"/>
                </div>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the image. Are you sure ?</p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapGetters } from 'vuex';

export default {
    computed : { ...mapGetters(['currentUser']) },
    props    : {
        gallery_id : { type : String },
        details    : { type : String }
    },
    name : 'GalleryEventImage',
    data () {
        return {
            addUrl       : urls.admin.event.gallery.imageAddEdit,
            deleteUrl    : urls.admin.event.gallery.imageDelete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name  : '__slot:image',
                    title : 'Image'
                },
                {
                    name  : '__slot:is_thumbnail',
                    title : 'Whether Thumbnail'
                },
                {
                    name  : '__slot:disabled',
                    title : 'Disabled'
                },
                {
                    name       : '__slot:actions',
                    title      : 'Action',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        clearFile (file) {
            this.editingItem.image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Added Successfully', 'Success', {
                type : 'success'
            });
            const refs = this.$refs;
            refs.addModal.close();
            this.$emit('success');
        },

        formSuccess1 () {
            this.$notify('Updated Successfully', 'Success', {
                type : 'success'
            });
            const refs = this.$refs;
            refs.editModal.close();
            this.$emit('success');
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.deletingItem.gallery_id = this.gallery_id;
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$emit('success');
        }
    }
};
</script>
