var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bs-4"},[_c('div',{staticClass:"fl-te-c mb-3"},[_c('h4',[_vm._v("Gallery Images")]),_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})],1),(_vm.details && _vm.details.length!==0)?_c('simple-table',{ref:"table",attrs:{"data":_vm.details,"fields":_vm.fields},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var rowData = ref.rowData;
return [_c('img',{attrs:{"src":rowData.image,"width":"200","height":"200","alt":"Avatar"}})]}},{key:"is_thumbnail",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.is_thumbnail)?_c('p',[_vm._v("Yes")]):_c('p',[_vm._v("No")])]}},{key:"disabled",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.disabled)?_c('p',[_vm._v("Yes")]):_c('p',[_vm._v("No")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('edit-btn',{on:{"click":function($event){return _vm.setEdit(rowData)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}})],1)]}}],null,false,3811962813)}):_c('p',{staticClass:"text-center p-3"},[_vm._v("No data available")]),_c('modal',{ref:"addModal",attrs:{"title":"Add Image","width":"30r","header-color":"primary","no-close-on-backdrop":""}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{gallery_id: _vm.gallery_id, api_key: _vm.currentUser.api_key}},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('file-input',{staticClass:"c-file-input",attrs:{"label":"Image","disabled":loading},model:{value:(model.image),callback:function ($$v) {_vm.$set(model, "image", $$v)},expression:"model.image"}})],1),_c('div',{staticClass:"col-12"},[_c('image-info',{attrs:{"width":360,"height":360}})],1),_c('div',{staticClass:"col-6"},[_c('checkbox-input',{attrs:{"label":"Is Thumbnail","disabled":loading},model:{value:(model.is_thumbnail),callback:function ($$v) {_vm.$set(model, "is_thumbnail", $$v)},expression:"model.is_thumbnail"}})],1),_c('div',{staticClass:"col-6"},[_c('checkbox-input',{attrs:{"label":"Disabled","disabled":loading},model:{value:(model.disabled),callback:function ($$v) {_vm.$set(model, "disabled", $$v)},expression:"model.disabled"}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('btn',{staticClass:"trn",attrs:{"text":"Save","loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)])]}}])})],1),_c('modal',{ref:"editModal",attrs:{"title":"Edit Image","width":"30r","header-color":"primary","no-close-on-backdrop":""}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{gallery_id: _vm.gallery_id, api_key: _vm.currentUser.api_key},"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"fl-te-c mb-2"},[_c('label',[_vm._v("Image")]),(_vm.editingItem.image)?_c('btn',{staticClass:"fa fa-eye",attrs:{"type":"button","size":"xs"},on:{"click":function($event){return _vm.viewFile(_vm.editingItem.image)}}}):_vm._e()],1),_c('file-input',{ref:"file",staticClass:"c-file-input",attrs:{"disabled":loading},on:{"input":_vm.clearFile},model:{value:(model.image),callback:function ($$v) {_vm.$set(model, "image", $$v)},expression:"model.image"}})],1),_c('div',{staticClass:"col-12"},[_c('image-info',{attrs:{"width":360,"height":360}})],1),_c('div',{staticClass:"col-6"},[_c('checkbox-input',{attrs:{"label":"Is Thumbnail","disabled":loading},model:{value:(model.is_thumbnail),callback:function ($$v) {_vm.$set(model, "is_thumbnail", $$v)},expression:"model.is_thumbnail"}})],1),_c('div',{staticClass:"col-6"},[_c('checkbox-input',{attrs:{"label":"Disabled","disabled":loading},model:{value:(model.disabled),callback:function ($$v) {_vm.$set(model, "disabled", $$v)},expression:"model.disabled"}})],1)]),_c('div',{staticClass:"col-12 text-right"},[_c('btn',{staticClass:"trn",attrs:{"text":"Update","loading-text":"Updating...","size":"sm","disabled":loading,"loading":loading}})],1)]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete. ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the image. Are you sure ?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }