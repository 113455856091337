<template>
    <vue-table-card title="Gallery" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10" ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                <delete-btn @click="setDelete(rowData)"/>
            </div>
        </template>
        <template #disabled="{rowData}">
            <p v-if="rowData.disabled">Yes</p>
            <p v-else>No</p>
        </template>

        <template #footer>
            <modal title="Add Gallery Event" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-gallery-event @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the Item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../../data/urls';
import AddGalleryEvent from './AddGalleryEvent';

export default {
    name       : 'GalleryEventImages',
    components : { AddGalleryEvent },
    data () {
        return {
            listUrl      : urls.admin.event.gallery.list,
            addUrl       : urls.admin.event.gallery.addEdit,
            deleteUrl    : urls.admin.event.gallery.delete,
            editingItem  : null,
            deletingItem : null,
            fields       : [
                {
                    name      : 'event_title',
                    sortField : 'event_title',
                    title     : 'Event Title'
                },
                {
                    name      : 'create_date',
                    sortField : 'create_date',
                    title     : 'Created At'
                },
                {
                    name  : '__slot:disabled',
                    title : 'Disabled'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }
            ]
        };
    },
    methods : {
        viewDetails (id) {
            this.$router.push('/gallery-events/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type : 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
