<template>
    <b-form ref="editForm" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData" :save-params="{api_key: currentUser.api_key}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Event Title" v-model="model.event_title"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <checkbox-input label="Disabled" v-model="model.disabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                     :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name     : 'EditGalleryEvent',
    computed : { ...mapGetters(['currentUser']) },

    props : { initialData : { type : Object } },

    data () {
        return {
            addUrl : urls.admin.event.gallery.addEdit
        };
    },
    methods : {
        formSuccess () {
            this.$notify('Successfully Updated Gallery Event..!', 'Success',
                {
                    type : 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
